
































































































































































import BaseComponent from '@/core/base.component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'Privacy',
})
export default class Privacy extends BaseComponent {}
